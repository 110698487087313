import IsMobile from "../components/IsMobile";
import s from "./Home.module.css";

function Maps() {
	let isMobile = IsMobile();

	return (
		<div className={isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>Site Coverage Maps</div>
			<br />
			<br />
			<div>Woolston</div>
			<img
				className={s.image}
				src={require("../images/Woolston.jpg")}
				alt=""
			/>
			<br />
			<br />
			<div>Halswell</div>
			<img
				className={s.image}
				src={require("../images/Halswell.jpg")}
				alt=""
			/>
			{/* <br />
			<br />
			<div>Dunsandel</div>
			<img
				className={s.image}
				src={require("../images/Dunsandel.jpg")}
				alt=""
			/> */}
			{/* <br /> */}
			{/* <br /> */}
			{/* <div>Waipara</div>
			<img
				className={s.image}
				src={require("../images/Waipara.jpg")}
				alt=""
			/> */}
			{/* <br />
			<br />
			<div>Combined</div>
			<img
				className={s.image}
				src={require("../images/Combined.jpg")}
				alt=""
			/> */}
			<br />
			<br />
			<div>Waimate</div>
			<img
				className={s.image}
				src={require("../images/waimate-coverage.png")}
				alt=""
			/>
			{isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

export default Maps;
